<template>

  <form onsubmit="return false;" id="edit-category-modal" class="ui modal tiny form">
    <i class="close icon inside"></i>
    <div class="header">
      <i class="tag icon"></i> Modification d'une catégorie
    </div>
    <div class="content">
      <div class="description">
        <div class="field">
          <label>Nom</label>
          <input type="text" name="name" :value="category.name"  placeholder="Ex.: Fruits & légumes">
        </div>
        <div class="field">
          <label>Couleur</label>
          <input type="color" name="color" :value="category.color">
        </div>
        <div class="ui error message">
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="delete ui icon red button"
              :data-id="category.id">
        <i class="trash icon"></i> Supprimer
      </button>
      <div class="ui positive submit right labeled icon button">
        Valider
        <i class="checkmark icon"></i>
      </div>
    </div>
  </form>
  
</template>

<script>
 
 export default {
   name: 'EditStockCategoryModal',
   data() {
     return {
       category: {}
     }
   },
   emits: [ 'interface', 'editedCategory', 'deletedCategory' ],
   updated() {},
   mounted() {
     this.emitInterface();
     // modal settings
     $('#edit-category-modal').modal({
       onDeny    : () => {},
       onApprove : () => { return false }
     })
     /* $('.selection.dropdown').dropdown() */
     // form validation
     $('#edit-category-modal.ui.form').form({
       keyboardShortcuts: false,
       fields: {
         name: {
           identifier: 'name',
           rules: [
             {
               type   : 'empty',
               prompt : 'Un nom pour la catégorie est nécessaire!'
             }
           ]
         }
       }
     })
   },
   unmounted() {},
   methods: {
     emitInterface() {
       this.$emit("interface", {
         
         editCategory: (category) => {
           this.category = category
           
           $('#edit-category-modal .delete.button').api({
             action: 'delete category',
             method: 'DELETE',
             urlData: { id: category.id },
             onSuccess: (response) => {
               this.$emit('deletedCategory', response.deletedCategoryId)
               $('#edit-category-modal').modal('hide')
             }
           })
           
           $('#edit-category-modal .positive.button').api({
             action: 'update category',
             method: 'PATCH',
             urlData: { id: category.id },
             contentType: 'application/json',
             serializeForm: true,
             beforeSend: function(settings) {
               // test if form is validated
               if ($('#edit-category-modal.ui.form').form('is valid')) {
                 let data = JSON.stringify(settings.data)
                 settings.data = data
                 return settings
               }
               else { return false }
             },
             onSuccess: (response) => {
               $('.ui.modal').modal('hide')
               // communicate the new category to the parent
               this.$emit('editedCategory', response.data)
             },
             onFailure: (response) => {
               $('#edit-category-modal.ui.form').form('add errors', [response.message])
             },
           })
         }
         
       })
     }
   }
 }
</script>

<style scoped>
 
</style>
