<template>
  <div>
    <div class="ui container">
      <button v-show="!shoppingMode" @click="clickShoppingButton" class="ui primary icon button"><i class="shopping cart icon"></i> Faire les courses</button>
      <button v-show="shoppingMode" @click="clickCancelShoppingButton" class="ui negative icon button"><i class="cancel icon"></i> Annuler</button>
      <button v-show="shoppingMode" id="buy-button" :class ="{ disabled: (Object.keys(inCartItems).length === 0) }" class="ui positive icon button"><i class="check icon"></i> Valider</button>
    </div>
    
    <div class="ui middle big aligned list">
      <template v-for="category in nonEmptyCategories">
        <h4 class="ui horizontal divider header title"
            :id="`category-header-${category.id}`" >
          <i class="tag icon"
             :style="{ color: category.color }"></i> 
          {{ category.name }}
        </h4>

        <GroceryItem v-for ="item in _getItemsToBeBought(category.id)"
                     :item="item"
                     :shoppingMode="shoppingMode"
                     @changeInCartQuantity="changeInCartQuantity"
                     @addedToCart="addedToCart" />
        
      </template>
      

    </div>

    <h4 v-if="shoppingMode" class="ui horizontal cart divider header">
      <i class="cart icon"></i>
    </h4>
    
    <div class="ui middle aligned list">
      <InCartItem v-for="item in inCartItems"
                  :item="item"
                  @removeItemFromCart="removeItemFromCart" />
    </div>
  </div>
</template>


<script>

 import GroceryItem from './Grocery/GroceryItem.vue'
 import InCartItem from './Grocery/InCartItem.vue'
 
 export default {
   name: 'GroceryList',
   components: { GroceryItem, InCartItem },
   props: {},
   data() {
     return {
       items: [],
       categories: [],
       inCartItems: {},
       shoppingMode: false
     }
   },
   computed: {
     nonEmptyCategories() {
       let cats = this.categories.filter(cat => (this._getItemsToBeBoughtCount(cat.id) > 0))
       if (this._getItemsToBeBoughtCount("") > 0) {
         cats.push({ name: "Non catégorisé", id: "", collapsed: false})
       }
       return cats
     }
   },
   mounted() {
     $.api({
       action: 'get categories',
       on: 'now',
       onSuccess: (response) => { this.categories = response.data }
     })
     $.api({
       action: 'get to be bought items',
       on: 'now',
       onSuccess: (response) => { this.items = response.data }
     })
     
     $('#buy-button').api({
       action: 'buy items',
       method : 'POST',
       contentType: 'application/json',
       beforeSend: (settings) => {
         let data = []
         for (const item in this.inCartItems) {
           data.push({
             id: this.inCartItems[item].id,
             bought_quantity: this.inCartItems[item].quantity
           })
         }
         settings.data = JSON.stringify(data)
         return settings
       },
       onSuccess: (response) => {
         this.inCartItems = {}
         this.items = response.data
         for (let item of this.items) {
           item.to_be_added_quantity = item.wanted_quantity
         }
         this.shoppingMode = false
       },
       onFailure: (response) => {},
     })
   },
   methods: {
     getQuantityToBeBought: function (item) {
       const inCartQuantity = (typeof this.inCartItems[item.id] === 'undefined') ? 0 : this.inCartItems[item.id].quantity
       return item.quantity_to_be_bought - inCartQuantity
     },
     clickShoppingButton: function () {
       for (let item of this.items) {
         item.to_be_added_quantity = item.still_needed_quantity
       }
       this.shoppingMode = true
     },
     clickCancelShoppingButton: function () {
       this.shoppingMode = false
       for (const itemId in this.inCartItems) {
         this.removeItemFromCart(itemId)
       }
     },
     changeInCartQuantity: function (id, type) {
       const delta = (type === 'increment') ? 1 : -1
       const index = this.items.findIndex( item => item.id === id)
       this.items[index].to_be_added_quantity += delta
     },
     addedToCart: function (item) {
       const new_incart_quantity = (typeof this.inCartItems[item.id] === 'undefined') ? item.to_be_added_quantity : this.inCartItems[item.id].quantity + item.to_be_added_quantity
       this.inCartItems[item.id] = {
         id: item.id,
         name: item.name,
         quantity: new_incart_quantity,
       }
       const index = this.items.findIndex( obj => obj.id === item.id )
       this.items[index].still_needed_quantity = this.items[index].wanted_quantity - new_incart_quantity
       this.items[index].to_be_added_quantity = this.items[index].still_needed_quantity
     },
     _getToBuyItemsCount: function (category_id) {
       return this.items.filter( item => ((item.quantity_to_be_bought > 0) && (item.category_id == category_id))).length
     },
     _getItemsToBeBought: function (cat_id) {
       const filteredItems = this.items.filter(item => { return (item.still_needed_quantity > 0 && item.category_id == cat_id) } )
       return filteredItems
     },
     _getItemsToBeBoughtCount: function (cat_id) {
       const itemsToBeBought = this._getItemsToBeBought(cat_id)
       return itemsToBeBought.length
     },
     removeItemFromCart: function (itemId) {
       delete this.inCartItems[itemId]
       
       const index = this.items.findIndex( item => item.id === itemId )
       this.items[index].still_needed_quantity = this.items[index].wanted_quantity
       this.items[index].to_be_added_quantity = this.items[index].wanted_quantity
     }
   }
 }
</script>

<style scoped>
 .ui.horizontal.divider.header.title {
     margin-top: 0.3em;
     margin-bottom: 0.3em;
 }
 .name {
     padding-right: 0.5em;
 }
 .type_of_quantity {
     color: grey;
     font-weight: bold;
 }
 i.icon.tag {
     font-size: 1em;
     margin: 0;
 }
 .cart.divider::before, .cart.divider::after {
     background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAADCAIAAADqcj94AAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9bS6VUHOwg4pChFQcLoiKOWoUiVAi1QqsOJpd+QZOGJMXFUXAtOPixWHVwcdbVwVUQBD9AHJ2cFF2kxP8lhRYxHhz34929x907wN+sMtXsGQdUzTIyqaSQy68KoVcEEUYIcYxKzNTnRDENz/F1Dx9f7xI8y/vcn6NPKZgM8AnEs0w3LOIN4ulNS+e8TxxlZUkhPiceM+iCxI9cl11+41xy2M8zo0Y2M08cJRZKXSx3MSsbKvEUcUxRNcr351xWOG9xVqt11r4nf2GkoK0sc53mMFJYxBJECJBRRwVVWEjQqpFiIkP7SQ//kOMXySWTqwJGjgXUoEJy/OB/8Ltbszg54SZFkkDwxbY/4kBoF2g1bPv72LZbJ0DgGbjSOv5aE5j5JL3R0WJHQP82cHHd0eQ94HIHGHzSJUNypABNf7EIvJ/RN+WBgVsgvOb21t7H6QOQpa7SN8DBITBSoux1j3f3dvf275l2fz9mTnKiOTlrogAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+YFCgcmDGSz5KEAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAI0lEQVRo3u3BMQEAAADCoPVPbQhfoAAAAAAAAAAAAAAAAOAyMqMAAbHnkvMAAAAASUVORK5CYII=');
 }
</style>
