<template>

  <form onsubmit="return false;" id="add-item-modal" class="ui modal tiny form">
    <i class="close icon inside"></i>
    <div class="header">
      <i class="fruit-apple icon"></i> Nouvel objet
    </div>
    <div class="content">
      <div class="description">
        <div class="field">
          <label>Nom</label>
          <input type="text" name="name" placeholder="Ex.: Farine">
        </div>
        <div class="field">
          <label>Type de quantité</label>
          <input type="text" name="unit" placeholder="Ex.: Paquet (1 Kg)">
        </div>
        <div class="field">
          <label>Catégorie</label>
          <div class="ui selection dropdown">
            <input type="hidden" name="category_id">
            <div class="text"></div>
            <i class="dropdown icon"></i>
          </div>
        </div>
        <div class="ui error message">
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="ui black deny button">
        Annuler
      </div>
      <div class="ui positive submit right labeled icon button">
        Valider
        <i class="checkmark icon"></i>
      </div>
    </div>
  </form>
  
</template>

<script>
 
 export default {
   name: 'AddStockItemModal',
   emits: [ 'addedItem' ],
   props: [ 'categories' ],
   computed: {
     dropdownCategories() {
       let cats = [ {name: "Aucune", value: "", selected: true} ]
       for (const cat of this.categories) {
         cats.push({name: cat.name, value: cat.id})
       }
       return cats
     }
   },
   updated() {
     $('#add-item-modal .ui.dropdown').dropdown({
         values: this.dropdownCategories
       })
   },
   mounted() {
     // modal settings
     $('.ui.modal').modal({
       onDeny    : () => {},
       onApprove : () => { return false }
     })
     
     $('.selection.dropdown').dropdown()
     // form validation
     $('#add-item-modal.ui.form').form({
       keyboardShortcuts: false,
       fields: {
         name: {
           identifier: 'name',
           rules: [
             {
               type   : 'empty',
               prompt : 'Un nom pour l\'objet est nécessaire!'
             }
           ]
         },
         unit: {
           identifier: 'unit',
         }
       }
     })
     // form api action
     $('#add-item-modal .positive.button').api({
       action: 'add item',
       method: 'POST',
       contentType: 'application/json',
       serializeForm: true,
       beforeSend: function(settings) {
         // test if form is validated
         if ($('#add-item-modal.ui.form').form('is valid')) {
           // open console to inspect object
           /* console.log(settings.data) */
           let data = JSON.stringify(settings.data)
           settings.data = data
           return settings
         } else {
           return false
         }
       },
       onSuccess: (response) => {
         $('.ui.modal').modal('hide')
         // communicate the new item to the parent
         this.$emit('addedItem', response.data)
       },
       onFailure: (response) => {
         $('#add-item-modal.ui.form').form('add errors', [response.message])
       },
     })
   },
   methods: {}
 }
</script>

<style scoped>
 
</style>
