<template>

  <form onsubmit="return false;" id="add-category-modal" class="ui modal tiny form">
    <i class="close icon inside"></i>
    <div class="header">
      <i class="tag icon"></i>Nouvelle catégorie
    </div>
    <div class="content">
      <div class="description">
        <div class="field">
          <label>Nom</label>
          <input type="text" name="name" placeholder="Ex.: Fruits & Légumes">
        </div>
        <div class="field">
          <label>Couleur</label>
          <input type="color" name="color">
        </div>
        <div class="ui error message">
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="ui black deny button">
        Annuler
      </div>
      <div class="ui positive submit right labeled icon button">
        Valider
        <i class="checkmark icon"></i>
      </div>
    </div>
  </form>
  
</template>

<script>
 export default {
   name: 'AddStockCategoryModal',
   emits: [ 'addedCategory' ],
   props : {},
   data() { return {} },
   updated() {},
   mounted() {
     // modal settings
     $('.ui.modal').modal({
       onDeny    : () => {},
       onApprove : () => { return false }
     })
     // form validation
     $('#add-category-modal.ui.form').form({
       keyboardShortcuts: false,
       fields: {
         name: {
           identifier: 'name',
           rules: [
             {
               type   : 'empty',
               prompt : 'Un nom pour la catégorie est nécessaire!'
             }
           ]
         },
       }
     })
     // form api action
     $('#add-category-modal .positive.button').api({
       action: 'add category',
       method: 'POST',
       contentType: 'application/json',
       serializeForm: true,
       beforeSend: function (settings) {
         // test if form is validated
         if ($('#add-category-modal.ui.form').form('is valid')) {
           let data = JSON.stringify(settings.data)
           settings.data = data
           return settings
         }
         else { return false }
       },
       onSuccess: (response) => {
         $('.ui.modal').modal('hide')
         // communicate the new category to the parent
         this.$emit('addedCategory', response.data)
       },
       onFailure: (response) => {
         $('#add-category-modal.ui.form').form('add errors', [response.message])
       },
     })
   },
   unmounted() {
     $('.ui.modal').modal('destroy')
     $('.ui.modal').remove()
   },
   methods: {}
 }
</script>

<style scoped>
 
</style>
