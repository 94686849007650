<template>
  <div>
    <!-- Modals -->
    <AddStockItemModal
      @added-item="newItemAdded"
      :categories="categories"
    />
    <EditStockItemModal 
      @interface="getChildInterface"
      @edited-item="itemEdited"
      @deleted-item="itemDeleted"
      :categories="categories"
    />
    <AddStockCategoryModal
      @added-category="newCategoryAdded"
    />
    <EditStockCategoryModal 
      @interface="getChildInterface"
      @edited-category="categoryEdited"
      @deleted-category="categoryDeleted"
    />
    <!-- Add buttons -->
    <div class="ui container">
      <button @click="clickAddItemButton" class="ui primary button">
        <i class="plus icon"></i>Objet
      </button>
      <button @click="clickAddCategoryButton" class="ui primary button">
        <i class="plus icon"></i>Catégorie
      </button>
    </div>
    <!-- Items -->
    <h4 class="ui horizontal divider header">
      <i class="carrot icon"></i>
      Objets
    </h4>
    <div class="ui middle big aligned list">
      <StockItem v-for="item in orderedColoredItems"
                 :item="item"
                 @editItem="editItem"
                 @itemQuantityChanged="itemQuantityChanged"
      />
    </div>
    <!-- Categories -->
    <h4 class="ui horizontal divider header">
      <i class="tags icon"></i>
      Catégories
    </h4>
    <div class="ui middle big aligned list">
      <StockCategory v-for="(category, index) in categories"
                     :category="category"
                     :first="index == 0"
                     :last="index == categories.length-1"
                     @editCategory="editCategory"
                     @categoryMovedUp="categoryMovedUp"
                     @categoryMovedDown="categoryMovedDown"
      />
    </div>
    
  </div>
</template>

<script>

 import AddStockItemModal from './Stock/AddStockItemModal.vue'
 import EditStockItemModal from './Stock/EditStockItemModal.vue'
 import AddStockCategoryModal from './Stock/AddStockCategoryModal.vue'
 import EditStockCategoryModal from './Stock/EditStockCategoryModal.vue'
 import StockItem from './Stock/StockItem.vue'
 import StockCategory from './Stock/StockCategory.vue'

 export default {
   name: 'Stock',
   components: {
     AddStockItemModal,
     EditStockItemModal,
     AddStockCategoryModal,
     EditStockCategoryModal,
     StockItem,
     StockCategory
   },
   props: {},
   data() {
     return {
       items: [],
       categories: [],
       showStockItemsList: true,
       refreshListTrigger: false,
     }
   },
   childInterface: {
     editItem:     () => {},
     editCategory: () => {}
   },
   computed: {
     orderedColoredItems() {
       let orderedColoredItems = []
       for (const cat of this.categories) {
         let items = this.items.filter((item) => {
           return item.category_id == cat.id
         })
         items = items.map((item) => {
           item.category_color = cat.color
           return item
         })
         orderedColoredItems = orderedColoredItems.concat(items)
       }
       
       const nonCategorizedItems = this.items.filter((item) => {
         return item.category_id == ""
       })
       orderedColoredItems = orderedColoredItems.concat(nonCategorizedItems)
       return orderedColoredItems
     }
   },
   mounted() {
     // initial data retrieval
     $.api({
       action: 'get items',
       on: 'now',
       onSuccess: (response) => { this.items = response.data }
     })
     $.api({
       action: 'get categories',
       on: 'now',
       onSuccess: (response) => { this.categories = response.data }
     })
   },
   async updated() {},
   methods: {
     clickAddItemButton: function () {
       $('#add-item-modal').modal('show')
     },
     clickAddCategoryButton: function () {
       $('#add-category-modal').modal('show')
     },
     newItemAdded: function (item) {
       this.items.push(item)
     },
     itemEdited: function (editedItem) {
       let position = this.items.findIndex((item) => item.id == editedItem.id)
       this.items[position] = editedItem
     },
     itemDeleted: function (id) {
       let position = this.items.findIndex((item) => item.id == id)
       this.items.splice(position, 1)
     },
     itemQuantityChanged: function (changedItem) {
       this.items = this.items.map(item => {
         if (item.id === changedItem.id)
           item.wanted_quantity = changedItem.wanted_quantity;
         return item
       })
     },
     editItem: function (itemToEdit) {
       this.$options.childInterface.editItem(itemToEdit)
       $('#edit-item-modal').modal('show')
     },
     newCategoryAdded: function (category) {
       this.categories.push(category)
     },
     categoryEdited: function (editedCategory) {
       let position = this.categories.findIndex((cat) => cat.id == editedCategory.id)
       this.categories[position] = editedCategory
     },
     categoryDeleted: function (id) {
       let position = this.categories.findIndex((cat) => cat.id == id)
       this.categories.splice(position, 1)
     },
     categoryMovedUp: function (id) {
       let position = this.categories.findIndex((cat) => cat.id == id)
       let tmpCat = this.categories[position]
       let tmpCat1 = this.categories[position-1]
       this.categories[position-1] = tmpCat
       this.categories[position] = tmpCat1
     },
     categoryMovedDown: function (id) {
       let position = this.categories.findIndex((cat) => cat.id == id)
       let tmpCat = this.categories[position]
       let tmpCat1 = this.categories[position+1]
       this.categories[position+1] = tmpCat
       this.categories[position] = tmpCat1
     },
     editCategory: function (categoryToEdit) {
       this.$options.childInterface.editCategory(categoryToEdit)
       $('#edit-category-modal').modal('show')
     },
     getChildInterface(childInterface) {
       for (const func in childInterface) {
         this.$options.childInterface[func] = childInterface[func]
       }
     }
   }
 }
</script>

<style scoped>
 .list {
     text-align: left
 }
</style>
