<template>
  
  <LoginModal v-if="!authenticated"
              @gotToken="login" />
  
  <h2 id="greeting" v-if="authenticated">{{ greetingWord }} {{ username }} ! <em data-emoji="sunny"></em> <a @click="logout" id="not-you-link">Ce n'est pas toi ?</a> </h2>
  <div class="ui top three item attached tabular menu">
    <a @click="currentSelection = 0" class="item" :class="{ active: currentSelection == 0 }">
      Stock actuel
    </a>
    <a @click="currentSelection = 1" class="item" :class="{ active: currentSelection == 1 }">
      Liste de courses
    </a>
    <a @click="currentSelection = 2" class="item" :class="{ active: currentSelection == 2 }">
      Réserves
    </a>
  </div>
  <div class="ui bottom attached segment">
    <CurrentItems v-if="currentSelection == 0 && authenticated" />
    <GroceryList v-else-if="currentSelection == 1 && authenticated" />
    <Stock v-else-if="currentSelection == 2 && authenticated" />
  </div>
</template>

<script>
 
 import CurrentItems from './components/CurrentItems.vue'
 import GroceryList from './components/GroceryList.vue'
 import Stock from './components/Stock.vue'
 import LoginModal from './components/LoginModal.vue'

 import jwt_decode from "jwt-decode"

 import api from './api.js'

 export default {
   name: 'App',
   components: {
     CurrentItems,
     GroceryList,
     Stock,
     LoginModal
   },
   data() {
     return {
       authenticated: null,
       username: "",
       currentSelection: 0
     }
   },
   created() {
     // initialize api
     api(this.$data)
     
     const localToken = localStorage.getItem('jwt')
     // if there no token is stored in localStorage
     if (localToken == null || localToken === "undefined") {
       this.authenticated = false
     } else { // if a jwt is stored in localStorage
       this.login(localToken)
       // TODO Maybe validate the token through the server?
     }
   },
   mounted() {
   },
   methods: {
     login: function(token) {
       localStorage.setItem('jwt', token)
       
       const decodedToken = jwt_decode(token)

       this.authenticated = true
       this.username = decodedToken.name
       this.getGreeting()
     },
     logout: function () {
       localStorage.removeItem('jwt')
       location.reload()
     },
     getGreeting: function () {
       const rng = Math.random()
       switch (true) {
         case rng > 0.67:
           const today = new Date();
           const time = today.getHours()
           if (time >= 18) {
             this.greetingWord = "Bonsoir"
           } else {
             this.greetingWord = "Bonjour"
           }
           break
         case rng > 0.33:
           this.greetingWord = "Coucou"
           break
         default:
           this.greetingWord = "Salut"
       }
     }
     
   }
 }
</script>

<style>
 #app {
     font-family: Avenir, Helvetica, Arial, sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     text-align: center;
     color: #2c3e50;
     margin-top: 15px;
 }
 #greeting {
     margin: 26px 0px;
 }
 #not-you-link {
     font-size: 0.5em;
     cursor: pointer;
     white-space: nowrap;
 }
 
</style>
