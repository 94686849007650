const apiUrl = "https://autocourses.titibandit.eu:7000/"

export default function api(wmData) {

  $.fn.api.settings.api = {
    // auth
    'get token': apiUrl+'auth',
    // items
    'get items': apiUrl+'items',
    'consume item': apiUrl+'item/consume/{id}',
    'get to be bought items' : apiUrl+'items/to-buy',
    'buy items': apiUrl+'items/buy',
    'add item': apiUrl+'item',
    'update item': apiUrl+'item/{id}',
    'delete item': apiUrl+'item/{id}',
    'increment wanted quantity': apiUrl+'item/increment-wanted-quantity/{id}',
    'decrement wanted quantity': apiUrl+'item/decrement-wanted-quantity/{id}',
    // categories
    'get categories': apiUrl+'categories',
    'add category': apiUrl+'category',
    'delete category': apiUrl+'category/{id}',
    'update category': apiUrl+'category/{id}',
    'moveup category': apiUrl+'category/move-up/{id}',
    'movedown category': apiUrl+'category/move-down/{id}'
  }

  $.fn.api.settings.successTest = function(response) {
    if(response && response.success) {
      return response.success;
    }
    return false;
  }

  $.fn.api.settings.beforeXHR = function(xhr) {
    const token = localStorage.getItem('jwt')
    xhr.setRequestHeader ('Authorization', 'Bearer '+token)
    return xhr
  }

  $.fn.api.settings.onError = async function(errorMessage, element, xhr) {
    // when unauthorized, we retrieve a new token
  //   if(xhr.status === 401) {
  //     const params = (new URL(document.location)).searchParams;
  //     const response = await fetch(apiUrl+'auth', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ code: params.get('c') })
  //     });
  //     const data = await response.json()
  //     localStorage.setItem('jwt', data.token)
  //   }
   }
  
}
