import './assets/fomantic/dist/semantic.css'
import './assets/fomantic/dist/semantic.js'

import 'form-serializer'

import { createApp } from 'vue'
import App from './App.vue'

const wm = createApp( App )
wm.mount('#app')
