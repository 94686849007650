<template>
  <div class="item">
    
    <!-- edit button -->
    <div class="right floated content">
      <button class="edit ui icon button" @click="clickEditCategoryButton">
        <i class="pen icon"></i>
      </button>
    </div>    
    <!-- arrow buttons -->
    <div class="right floated content">
      <div class="ui icon buttons">
        <button class="ui button"
                :id="`move-up-button-${category.id}`"
                :class="{ disabled: first }">
          <i class="chevron up icon"></i>
        </button>
        <button class="ui button"
                :id="`move-down-button-${category.id}`"
                :data-id="category.id"
                :class="{ disabled: last }">
          <i class="chevron down icon"></i>
        </button>
      </div>
    </div>
    <!-- name -->
    <div class="content vertical-center">
      <i class="tag icon"
         :style="{ color: category.color }"></i>
      {{ category.name }}
    </div>
    
  </div>
</template>

<script>
 export default {
   name: 'StockCategory',
   props: [ 'category', 'first', 'last' ],
   emits: [ 'editCategory', 'categoryMovedUp', 'categoryMovedDown' ],
   mounted() {
     this._setAPICalls()
   },
   updated() {
     this._setAPICalls()
   },
   methods: {
     clickEditCategoryButton: function () {
       this.$emit('editCategory', this.category)
     },
     _setAPICalls: function () {
       $('#move-up-button-'+this.category.id).api({
         action: 'moveup category',
         method : 'PATCH',
         urlData: {
           id: this.category.id
         },
         onFailure: (response) => {},
         onSuccess: (response) => { this.$emit('categoryMovedUp', this.category.id) },
       })
       $('#move-down-button-'+this.category.id).api({
         action: 'movedown category',
         method : 'PATCH',
         urlData: {
           id: this.category.id
         },
         onFailure: (response) => {},
         onSuccess: (response) => { this.$emit('categoryMovedDown', this.category.id) },
       })
     }
   }
 }
</script>

<style scoped>
 .vertical-center {
     margin: 0;
     position: absolute;
     top: 50%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 }
 .item {
     position: relative
 }  
</style>
