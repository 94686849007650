<template>

  <div class="item">

    <div class="left floated content">
      <div class="ui left labeled button" tabindex="0">
        <a class="ui basic label">{{ item.current_quantity }}</a>
        <div class="consume ui icon button red basic" :data-id="item.id">
          <i class="minus icon"></i>
        </div>
      </div>
    </div>

    <div class="content vertical-center">
      <div>{{ item.name }}</div>
      <div v-if="item.unit !== ''" class="unit">{{ item.unit }}</div>
    </div>
    
  </div>

</template>

<script>
  export default {
   name: 'CurrentItem',
   props: [ 'item' ],
 }
</script>

<style scoped>
 .unit {
     font-size: 0.7em;
     color: gray;
     line-height: 1em;
     color: gray;
 }
 .item {
     position: relative;
 }
 .vertical-center {
     margin: 0;
     position: absolute;
     top: 55%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 }
 .content.vertical-center {
     margin-left: 110px;
 }
</style>
