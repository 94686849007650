<template>
  <div class="item">
    
    <!-- edit button -->
    <div class="right floated content">
      <button class="edit ui icon button" @click="clickEditItemButton">
        <i class="pen icon"></i>
      </button>
    </div>
    <!-- quantity buttons -->
    <div class="right floated content">
      <div class="ui buttons">
        <div class="ui icon negative button active"
             :id="`decrement-button-${item.id}`"
             :class="{ disabled: item.wanted_quantity < 1 }"
             :data-id="item.id">
          <i class="minus icon"></i>
        </div>
        <div v-bind="{'data-text': item.wanted_quantity}" class="or"></div>
        <div class="ui icon positive button active"
             :id="`increment-button-${item.id}`"
             :data-id="item.id">
          <i class="plus icon"></i>
        </div>
      </div>
    </div>
    <!-- name -->
    <div class="content vertical-center">
      <a class="ui empty circular label vertical-center"
         :style="{ backgroundColor: item.category_color }"></a>
      <span>
        <div>{{ item.name }}</div>
        <div v-if="item.unit !== ''" class="unit">{{ item.unit }}</div>
      </span>
    </div>
    
  </div>
</template>

<script>
 export default {
   name: 'StockItem',
   props: [ 'item' ],
   emits: [ 'editItem', 'itemQuantityChanged' ],
   mounted() {
     
     $('#decrement-button-'+this.item.id).api({
       action: 'decrement wanted quantity',
       method : 'PATCH',
       onFailure: (response) => {},
       onSuccess: (response) => { this.$emit('itemQuantityChanged', response.data) },
     })
     $('#increment-button-'+this.item.id).api({
       action: 'increment wanted quantity',
       method : 'PATCH',
       onFailure: (response) => {},
       onSuccess: (response) => { this.$emit('itemQuantityChanged', response.data) },
     })
     
   },
   methods: {
     clickEditItemButton: function () {
       this.$emit('editItem', this.item)
     }
   }
 }
</script>

<style scoped>
 .vertical-center {
     margin: 0;
     position: absolute;
     top: 50%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 }
 .content.vertical-center {
     display: inline-flex;
 }
 span {
     margin-left: 20px;
 }
 .item {
     position: relative;
 }
 .unit {
     font-size: 0.7em;
     color: gray;
     line-height: 1em;
     color: gray;
 }
</style>
