<template>

  <div class="item">
        
        <div class="right floated content">
          <div class="ui equal width grid"
               :style="{visibility: shoppingMode ? 'visible' : 'hidden' }">
            <div class="row">
              <div class="column">
                <div class="ui buttons">
                  <div @click="clickChangeInCartQuantityButton(item.id, 'decrement')"
                              class="ui icon button active"
                              :class="{disabled: item.to_be_added_quantity <= 1}">
                    <i class="minus icon"></i>
                  </div>
                  <div v-bind="{'data-text': item.to_be_added_quantity}" class="or"></div>
                  <div @click="clickChangeInCartQuantityButton(item.id, 'increment')"
       class="ui icon button active">
                    <i class="plus icon"></i>
                  </div>
                </div>
              </div>
              <div class="column">
                <button @click="clickAddedToCartButton(item)" class="ui primary icon button">
                  <i class="cart arrow down icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="content vertical-center">
          <div class="ui vertical-center quantity_to_buy">
            {{ item.still_needed_quantity }}
          </div>
          <div class="name">
            <div>{{ item.name }}</div>
            <div v-if="item.unit !== ''" class="unit">{{ item.unit }}</div>
          </div>
        </div>
        
  </div>
  
</template>

<script>
 export default {
   name: 'GroceryItem',
   props: [ 'item', 'shoppingMode' ],
   emits: [ 'changeInCartQuantity', 'addedToCart' ],
   methods: {
     clickChangeInCartQuantityButton: function (itemId, type) {
       this.$emit('changeInCartQuantity', itemId, type)
     },
     clickAddedToCartButton: function (itemId, quantity) {
       this.$emit('addedToCart', itemId, quantity)
     }
   }
 }
</script>

<style>
 .unit {
     font-size: 0.7em;
     color: gray;
     line-height: 1em;
     color: gray;
 }
 .vertical-center {
     text-align: left;
 }
 .item {
     position: relative;
 }
 .name {
     margin-left: 20px;
 }
 .quantity_to_buy {
     font-size: 1.3em;
 }
 .vertical-center {
     margin: 0;
     position: absolute;
     top: 55%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 } 
</style>
