<template>

  <form onsubmit="return false;" id="edit-item-modal" class="ui modal tiny form">
    <i class="close icon inside"></i>
    <div class="header">
      <i class="lemon icon"></i> Modification d'un objet
    </div>
    <div class="content">
      <div class="description">
        <div class="field">
          <label>Nom</label>
          <input type="text" name="name" :value="item.name"  placeholder="Ex.: Farine">
        </div>
        <div class="field">
          <label>Type de quantité</label>
          <input type="text" name="unit" :value="item.unit" placeholder="Ex.: Paquet (1 Kg)">
        </div>
        <div class="field">
          <label>Catégorie</label>
          <div class="ui selection dropdown">
            <input type="hidden" name="category_id">
            <div class="text"></div>
            <i class="dropdown icon"></i>
          </div>
        </div>
        <div class="ui error message">
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="delete ui icon red button">
        <i class="trash icon"></i> Supprimer
      </button>
      <div class="ui positive submit right labeled icon button">
        Valider
        <i class="checkmark icon"></i>
      </div>
    </div>
  </form>
  
</template>

<script>
 
 export default {
   name: 'EditStockItemModal',
   props: [ 'categories' ],
   emits: [ 'interface', 'editedItem', 'deletedItem' ],
   data() {
     return {
       item: {}
     }
   },
   computed: {
     dropdownCategories() {
       let cats = [ { name: "Aucune", value: "" } ]
       for (const cat of this.categories) {
         if (cat.id == this.item.category_id)
           cats.push({ name: cat.name, value: cat.id, selected: true })
         else
           cats.push({ name: cat.name, value: cat.id })
       }
       return cats
     }
   },
   updated() {
     $('#edit-item-modal .ui.dropdown').dropdown({
       values: this.dropdownCategories
     })
   },
   mounted() {
     this.emitInterface()
     // modal settings
     $('#edit-item-modal').modal({
       onDeny    : () => {},
       onApprove : () => { return false }
     })
     /* $('.selection.dropdown').dropdown() */
     // form validation
     $('#edit-item-modal.ui.form').form({
       keyboardShortcuts: false,
       fields: {
         name: {
           identifier: 'name',
           rules: [
             {
               type   : 'empty',
               prompt : 'Un nom pour l\'objet est nécessaire!'
             }
           ]
         },
         unit: {
           identifier: 'unit',
         }
       }
     })
   },
   unmounted() {},
   methods: {
     emitInterface() {
       this.$emit("interface", {
         
         editItem: (item) => {
           this.item = item
           
           $('#edit-item-modal .delete.button').api({
             action: 'delete item',
             method: 'DELETE',
             urlData: { id: item.id },
             onSuccess: (response) => {
               this.$emit('deletedItem', response.deletedItemId)
               $('#edit-item-modal').modal('hide')
             }
           })
           
           $('#edit-item-modal .positive.button').api({
             action: 'update item',
             method: 'PATCH',
             urlData: { id: item.id },
             contentType: 'application/json',
             serializeForm: true,
             beforeSend: function(settings) {
               // test if form is validated
               if ($('#edit-item-modal.ui.form').form('is valid')) {
                 let data = JSON.stringify(settings.data)
                 settings.data = data
                 return settings
               } else {
                 return false
               }
             },
             onSuccess: (response) => {
               $('.ui.modal').modal('hide')
               // communicate the new item to the parent
               this.$emit('editedItem', response.data)
             },
             onFailure: (response) => {
               $('#edit-item-modal.ui.form').form('add errors', [response.message])
             },
           })
         }
         
       })
     }
   }
 }
</script>

<style scoped>
 
</style>
