<template>
  <div class="ui middle aligned list">

    <div class="ui accordion">
      
      <template v-for="category in nonEmptyCategories">
        <h4 class="ui horizontal divider header title"
            @click="_toggleCollapse(category.id)" 
            :id="`category-header-${category.id}`"
            :class="{ active: !category.collapsed }">
          <i class="dropdown icon"></i>
          <i class="tag icon"
             :style="{ color: category.color }"></i> 
          {{ category.name }} ({{ _getAvailableItemsCount(category.id) }})
        </h4>
        
        <div class="ui middle big aligned list content"
             :class="{ active: !category.collapsed }">
          <CurrentItem  v-if="_getAvailableItemsCount(category.id) > 0" v-for="item in _getAvailableItems(category.id)" :item="item" />
        </div>
      </template>
      
    </div>
    
  </div>
</template>

<script>
 
 import CurrentItem from './Current/CurrentItem.vue'
 import { nextTick } from 'vue'
 
 export default {
   name: 'CurrentItems',
   components: { CurrentItem },
   props: {},
   data() {
     return {
       items: [],
       categories: []
     }
   },
   computed: {
     nonEmptyCategories() {
       let cats = this.categories.filter(cat => this._getAvailableItemsCount(cat.id))
       if (this._getAvailableItemsCount("") > 0) {
         cats.push({ name: "Non catégorisé", id: "", collapsed: false})
       }
       return cats
     }
   },
   async mounted() {
     $.api({
       action: 'get categories',
       on: 'now',
       onSuccess: async (response) => {
         this.categories = response.data
         
         await nextTick()
         $('.ui.accordion').accordion({
           exclusive: false,
           duration: 500,
           animateChildren: false,
         })
       }
     })

     $.api({
       action: 'get items',
       on: 'now',
       onSuccess: (response) => { this.items = response.data }
     })

   },
   updated() {
     $('.consume.button').api({
       action: 'consume item',
       method : 'PATCH',
       onSuccess: (response) => {
         const index = this.items.findIndex(item => item.id === response.data.id)
         this.items[index] = response.data
       },
     })       
   },
   methods: {
     _getAvailableItems: function (category_id) {
       return this.items.filter( item => ((item.current_quantity > 0) && (item.category_id == category_id)))
     },
     _getAvailableItemsCount: function (category_id) {
       return this.items.filter( item => ((item.current_quantity > 0) && (item.category_id == category_id))).length
     },
     _toggleCollapse: async function (id) {
       if (id == "") {
         return
       }
       // let class apply to header
       await new Promise(r => setTimeout(r, 100));
       $.api({
         action: 'update category',
         method: 'PATCH',
         on: 'now',
         contentType: 'application/json',
         urlData: { id: id },
         data: JSON.stringify({ collapsed: ! $('#category-header-'+id).hasClass('active') }),
       })
     }
   }
 }
</script>

<style scoped>
 .title {
     margin: 0 0;
     padding: 0.5em 0em;
 }
 i.icon.tag {
     padding-right: 0.5em;
     font-size: 1em;
 }
</style>
