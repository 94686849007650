<template>
  
  <div class="item">
        <div class="right floated content">
          <div class="mini ui icon button red basic"
               @click="clickRemoveFromCartButton(item.id)">
            <i class="times icon"></i>
          </div>
        </div>
        <div class="content vertical-center">
          {{ item.quantity }} {{ item.name }} <span v-if="item.unit !== ''">{{ item.unit }}</span>
        </div>
  </div>
  
</template>

<script>
 export default {
   name: 'InCartItem',
   props: [ 'item' ],
   emits: [ 'removeItemFromCart' ],
   methods: {
     clickRemoveFromCartButton: function (itemId) {
       this.$emit('removeItemFromCart', itemId)
     }
   }
 }
</script>

<style>
 .vertical-center {
     margin: 0;
     position: absolute;
     top: 55%;
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
 }
</style>
