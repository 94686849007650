<template>

  <form onsubmit="return false;" id="login-modal" class="ui large modal form">
    <div class="center aligned header">Bienvenue sur Autocourses! <em data-emoji=":shopping_bags:"></em></div>
    <div class="center aligned content">
      <p>Il faut que tu t'authentifies à l'aide du code fourni pour accéder à tes Autocourses.</p>
    </div>
    <div id="code-fields" class="inline fields content">
      <div class="ui field">
        <label>Code</label>
        <input id="code-input" type="text" name="code" placeholder="XYZ42">
      </div>
      <div class="ui error message"></div>
    </div>
    <div class="center aligned actions">
      <div class="ui submit positive button">OK</div>
    </div>
  </form>
  
</template>

<script>
 export default {
   name: 'LoginModal',
   emits: [ 'gotToken' ],
   props : {},
   data() { return {} },
   computed: {},
   updated() {},
   mounted() {
     // form api action
     $('#login-modal .positive.button').api({
       action: 'get token',
       method: 'POST',
       serializeForm: true,
       contentType: 'application/json',
       beforeSend: (settings) => {
         if (this.isFormValid()) {
           settings.data = JSON.stringify(settings.data)
           return settings
         } else {
           return false
         }
       },
       onFailure: (response) => {
         $('#login-modal.ui.form').form('add errors', [response.message])
       },
       onSuccess: (response) => {
         this.$emit('gotToken', response.token)
         $('#login-modal').modal('hide')
         $('#login-modal').modal('hide dimmer')
       }
     })

     // form validation
     $('#login-modal').form({
       fields: {
         code: {
           identifier: 'code',
           rules: [
             {
               type   : 'exactLength[5]',
               prompt : 'Un code doit avoir {ruleValue} caractères.'
             }
           ]
         },
       }
     })
     
     // modal settings
     $('#login-modal').modal({
       closable: false,
       onApprove : () => { return false }
     }).modal('show')
     
     
   },
   unmounted() {
     $('.ui.modal').modal('destroy')
     $('.ui.modal').remove()
   },
   methods: {
     isFormValid: function () {
       if ($('#login-modal').form('is valid')) {
         return true
       } else {
         return false
       }
     }
   }
 }
</script>

<style scoped>
 #code-fields {
     margin-bottom: 0;
 }
 .field {
     text-align: center;
 }
 #code-input {
     width: 5.5em;
 }
</style>
